import React from 'react';

import { Link } from 'gatsby';

export interface IHeaderProps {
  className?: string;
  isSideNav?: boolean;
  activeClassName?: string;
}

export default function Links({ className, activeClassName, isSideNav = false }: IHeaderProps) {
  // const isCurrentGroup = (group: { name: string; to: string }[]) => {
  //   if (
  //     typeof location !== 'undefined' &&
  //     group.findIndex((o) => location.pathname.startsWith(o.to)) !== -1
  //   ) {
  //     return activeClassName;
  //   } else {
  //     return;
  //   }
  // };

  // const earnMoney = [
  //   {
  //     name: 'View all "Earn Money"',
  //     to: '/earn-money/',
  //   },
  //   {
  //     name: 'Side Gigs',
  //     to: '/tag/side-hustle/',
  //   },
  //   {
  //     name: 'Generate income',
  //     to: '/tag/generate-income/',
  //   },
  //   {
  //     name: 'Work From Home',
  //     to: '/tag/digital-nomad-life/',
  //   },
  //   {
  //     name: 'Millennial Money',
  //     to: '/tag/millennial-money/',
  //   },
  //   {
  //     name: 'Building Wealth',
  //     to: '/tag/build-wealth/',
  //   },
  // ];

  // const saveMoney = [
  //   {
  //     name: 'View all "Save Money"',
  //     to: '/save-money/',
  //   },
  //   {
  //     name: 'Top Money Saving Habits',
  //     to: '/tag/money-saving-habits/',
  //   },
  //   // {
  //   //   name: 'Top Money Saving Apps *',
  //   //   to: '/tag/money-saving-apps/',
  //   // },
  //   {
  //     name: 'Financial Tools',
  //     to: '/tag/financial-tools/',
  //   },
  //   {
  //     name: 'How to Budget',
  //     to: '/tag/budget/',
  //   },
  //   // {
  //   //   name: 'Best Budget Apps *',
  //   //   to: '/tag/budget/',
  //   // },
  // ];

  // const debt = [
  //   {
  //     name: 'View all "Debt"',
  //     to: '/debt/',
  //   },
  //   {
  //     name: 'Getting out of Debt',
  //     to: '/tag/getting-out-of-debt/',
  //   },
  //   {
  //     name: 'Opportunities to Save',
  //     to: '/tag/opportunities-to-save/',
  //   },
  //   {
  //     name: 'Financial Literacy',
  //     to: '/tag/financial-literacy/',
  //   },
  //   {
  //     name: 'Tackling Debt',
  //     to: '/tag/tackling-debt/',
  //   },
  //   {
  //     name: 'Extra Cash',
  //     to: '/tag/extra-cash/',
  //   },
  //   {
  //     name: 'Credit Cards',
  //     to: '/tag/credit-cards/',
  //   },
  // ];

  return (
    <ul className={className}>
      <li>
        <a href="https://discord.gg/wHb3wxTn" target="_blank">
          Community
        </a>
      </li>
      <li>
        <Link activeClassName={activeClassName} to="/posts/">
          Latest
        </Link>
      </li>
      {isSideNav && (
        <>
          <li>
            <Link to="/subscribe/">Subscribe</Link>
          </li>
          <li>
            <Link to="/advertise/">Advertise</Link>
          </li>
          <li>
            <Link to="/disclosure/">Disclosure</Link>
          </li>
          <li>
            <Link to="/contact/">Contact</Link>
          </li>
          <li>
            <Link to="/archives/">Post Archives</Link>
          </li>
          <li>
            <Link to="/about/">About</Link>
          </li>
        </>
      )}
      {/* <li>
        <Link activeClassName={activeClassName} to="/trending/">
          Trending
        </Link>
      </li>
      <li>
        {isSideNav && (
          <div className={isCurrentGroup(earnMoney)}>
            Earn Money <Chevron rotate={90} />
          </div>
        )}
        <ul>
          {earnMoney.map(({ name, to }, key) => (
            <li key={key}>
              <Link activeClassName={activeClassName} to={to}>
                {name}
              </Link>
            </li>
          ))}
        </ul>
        {!isSideNav && (
          <div className={isCurrentGroup(earnMoney)}>
            Earn Money <Chevron rotate={90} />
          </div>
        )}
      </li>
      <li>
        {isSideNav && (
          <div className={isCurrentGroup(saveMoney)}>
            Save Money <Chevron rotate={90} />
          </div>
        )}
        <ul>
          {saveMoney.map(({ name, to }, key) => (
            <li key={key}>
              <Link activeClassName={activeClassName} to={to}>
                {name}
              </Link>
            </li>
          ))}
        </ul>
        {!isSideNav && (
          <div className={isCurrentGroup(saveMoney)}>
            Save Money <Chevron rotate={90} />
          </div>
        )}
      </li>
      <li>
        {isSideNav && (
          <div className={isCurrentGroup(debt)}>
            Debt <Chevron rotate={90} />
          </div>
        )}
        <ul>
          {debt.map(({ name, to }, key) => (
            <li key={key}>
              <Link activeClassName={activeClassName} to={to}>
                {name}
              </Link>
            </li>
          ))}
        </ul>
        {!isSideNav && (
          <div className={isCurrentGroup(debt)}>
            Debt <Chevron rotate={90} />
          </div>
        )}
      </li>
      <li>
        <Link activeClassName={activeClassName} to="/about/">
          About
        </Link>
      </li>
      {isSideNav && (
        <li>
          <Link activeClassName={activeClassName} to="/stats/">
            Stats
          </Link>
        </li>
      )}
      <li>
        <Link activeClassName={activeClassName} to="/opt-in/">
          Opt In
        </Link>
      </li> */}
    </ul>
  );
}
